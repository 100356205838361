import * as React from "react";

const SpinnerLoader = () => {

    return (
        <div className="container-spinner-loader">
            <span className="loader"></span>
        </div>
    );
};

export default SpinnerLoader;