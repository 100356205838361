import * as React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { filterByKeyword, filterByClient } from '../../custom/functions'


const FilterPorfolioGeneral = ({ allProjects, areas, clients, setProjects, projectsPerPage, setPagination, setPage, location, setClientToSearch,
    setKeywordToSearch, setNotFound, keywordToSearch, myRef }) => {

    const allOptionsKeywords = areas.firstRow.concat(areas.secondRow)

    return (
        <div className="bg-white container-portfolio-general" id="filter-to-scroll" ref={myRef}>
            <div className="container-margin">
                <Row>
                    <Col sm="12" md="12" lg="8" className="container-portfolio-select mb-2" >
                        <Form.Select defaultValue={keywordToSearch ? keywordToSearch : areas.disabled.slug}
                            id="select-keywords"
                            onChange={(event) => filterByKeyword(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setKeywordToSearch,
                                setClientToSearch, setNotFound, event.target.value)}
                            className="fs-6 ILightBeta black">
                            <option className="fs-6 ILightBeta black" value={areas.disabled.slug} disabled>{areas.disabled.name}</option>
                            {allOptionsKeywords.map((area, index) => (
                                <option className="fs-6 ILightBeta black" value={area.slug}>{area.displayName}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col sm="12" md="12" lg="4" className="container-portfolio-select">
                        <Form.Select defaultValue={clients.disabled.slug}
                            id="select-client"
                            onChange={(event) => filterByClient(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setClientToSearch,
                                setKeywordToSearch, setNotFound, event.target.value, true)}
                            className="fs-6 ILightBeta">
                            <option className="fs-6 ILightBeta" value={clients.disabled.slug} disabled>{clients.disabled.name}</option>
                            {clients.options.map((client, index) => (
                                <>
                                    {client.childs.length > 0 ?
                                        <optgroup label={client.name}>
                                            {client.childs.map((child, index2) => (
                                                <option className="fs-6 ILightBeta" value={child.slug}>{child.name}</option>
                                            ))}
                                        </optgroup>
                                        :
                                        <option className="fs-6 ILightBeta" value={client.slug}>{client.name}</option>
                                    }
                                </>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FilterPorfolioGeneral;