import * as React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { filterByKeyword, filterByClient } from '../../custom/functions'


const FilterPorfolioGeneral = ({ allProjects, areas, clients, setProjects, projectsPerPage, setPagination, setPage, location, setClientToSearch,
    setKeywordToSearch, setNotFound, keywordToSearch, myRef  }) => {

    return (
        <div className="bg-white container-portfolio-general" id="filter-to-scroll" ref={myRef}>
            <div className="container-margin-small ">
                <Row>
                    <Col sm="12" md="12" lg="9">
                        <div className="d-block ">
                            <div className="d-flex container-keywords">
                                {areas.firstRow.map((item, index) => (
                                    <p className={location.search?.includes(item.slug) ? "fs-6 IBold cursor-pointer" : "fs-6 ILightBeta cursor-pointer"}
                                        key={item.slug + index} role="presentation"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            filterByKeyword(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setKeywordToSearch,
                                                setClientToSearch, setNotFound, item.slug)
                                        }}
                                        onKeyDown={(e) => {
                                            e.stopPropagation()
                                            filterByKeyword(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setKeywordToSearch,
                                                setClientToSearch, setNotFound, item.slug)
                                        }}
                                    >{item.displayName}</p>
                                ))}
                            </div>
                            <div className="d-flex container-keywords">
                                {areas.secondRow.map((item, index) => (
                                    <p className={location.search?.includes(item.slug) ? "fs-6 IBold cursor-pointer" : "fs-6 ILightBeta cursor-pointer"}
                                    role="presentation" onClick={(e) => {
                                            e.stopPropagation()
                                            filterByKeyword(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setKeywordToSearch,
                                                setClientToSearch, setNotFound, item.slug)
                                        }}
                                        onKeyDown={(e) => {
                                            e.stopPropagation()
                                            filterByKeyword(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setKeywordToSearch,
                                                setClientToSearch, setNotFound, item.slug)
                                        }}
                                        key={item.slug + index}>{item.displayName}</p>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md="12" lg="3" className="container-portfolio-select">
                        <Form.Select defaultValue={clients.disabled.slug}
                            id="select-client"
                            onChange={(event) => filterByClient(allProjects, setProjects, projectsPerPage, setPagination, setPage, location, setClientToSearch,
                                setKeywordToSearch, setNotFound, event.target.value)}
                            className="fs-6 ILightBeta">
                            <option className="fs-6 ILightBeta" value={clients.disabled.slug} disabled>{clients.disabled.name}</option>
                            {clients.options.map((client, index) => (
                                <>
                                    {client.childs.length > 0 ?
                                        <optgroup label={client.name}>
                                            {client.childs.map((child, index2) => (
                                                <option className="fs-6 ILightBeta" value={child.slug}>{child.name}</option>
                                            ))}
                                        </optgroup>
                                        :
                                        <option className="fs-6 ILightBeta" value={client.slug}>{client.name}</option>
                                    }
                                </>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FilterPorfolioGeneral;